import * as React from 'react';
import styles from './Myplan.module.scss';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FormControlLabel, FormGroup, Checkbox, Button,Box } from '@mui/material';
import { findIndex } from 'lodash';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import PaidIcon from '@mui/icons-material/Paid';
import ScheduleIcon from '@mui/icons-material/Schedule';


function MyPlan() {
  
    const isAdmin = useQuery(
        ['isAdmin'],
        async () => {
            const { data } = await axios.get('/user/is_admin');
            return data;
        }
    );
    
    
    const getMyPlan = useQuery(
        ['myPlan'],
        async () => {
          const { data } = await axios.get(`/subscription`);
            return data;
        }
      );

    const getMyPlanLink = useQuery(
        ['myPlanLink'],
        async () => {
          try{
            const { data } = await axios.get(`/user_purchase/get_stripe_customer_portal_link`);
            return data;
          }catch(error){
            if (error.response && error.response.status === 400) {
              return null;
            }
            else {
              throw error;
            }
          }
         
        },
        {
          enabled: !!getMyPlan?.data?.id
        }
    );

    const getMySites = useQuery(
      ['mySites', ],
      async () => {
        const { data } = await axios.get(`/subscription/get_existing_sites_under_subscription?subscription_id=${getMyPlan?.data?.id}`);
          return data;
      },
      {
        enabled: !!getMyPlan?.data?.id
      }
    );

    const getAllSites = useQuery(
      ['allSites'],
      async () => {
        const { data } = await axios.get(`/subscription/get_site_list_for_subscription`);
          return data;
      }
    );

  const addSite = useMutation(
      async (site_id: string) => {
          const { data } = await axios.put(`/subscription/add_site_to_subscription?subscription_id=${getMyPlan?.data?.id}&site_id=${site_id}`);
            return data;
        },
        {
          onSuccess: () => {
            getMySites.refetch();
            toast.success("Site added to subscription successfully!");
          },
          onError: (error) => {
            toast.error("Error adding site to subscription!");
          }
        }
    );

  const deleteSite = useMutation(
      async (site_id: string) => {
        const { data } = await axios.delete(`/subscription/remove_site_from_subscription?subscription_id=${getMyPlan?.data?.id}&site_id=${site_id}`);
          return data;
      },
      {
        onSuccess: () => {
          getMySites.refetch();
          toast.success("Site removed from subscription successfully!");
        },
        onError: (error) => {
          toast.error("Error removing site from subscription!");
        }
      }
  );

  const addSites = (site_id, e) => {
    if (e.target.checked) {
      if (getMySites?.data?.length >= getMyPlan.data?.max_no_of_sites) {
        toast.error("You have reached the maximum number of sites allowed for your subscription!");
      } else {
        addSite.mutate(site_id);
      }
    } else {
      deleteSite.mutate(site_id);
    }
  }
  const getMyUsers = useQuery(
    ['myUsers', ],
    async () => {
      const { data } = await axios.get(`/subscription/get_existing_users_subscription?subscription_id=${getMyPlan?.data?.id}`);
        return data;
    },
    {
      enabled: !!getMyPlan?.data?.id
    }
  );

  const getAllUsers = useQuery(
    ['allUsers'],
    async () => {
      const { data } = await axios.get(`/subscription/get_user_list_for_subscription`);
        return data;
    }
  );


const addUser = useMutation(
    async (user_id: string) => {
        const { data } = await axios.put(`/subscription/add_user_to_subscription?subscription_id=${getMyPlan?.data?.id}&user_id=${user_id}`);
          return data;
      },
      {
        onSuccess: () => {
          getMyUsers.refetch();
          toast.success("User added to subscription successfully!");
        },
        onError: (error) => {
            toast.error('Error adding user to subscription!');
        }
      }
  );

const deleteUser = useMutation(
    async (user_id: string) => {
      const { data } = await axios.delete(`/subscription/remove_user_from_subscription?subscription_id=${getMyPlan?.data?.id}&user_id=${user_id}`);
        return data;
    },
    {
      onSuccess: () => {
        getMyUsers.refetch();
        toast.success("User removed from subscription successfully!");
      },
      onError: (error) => {
          toast.error(error?.response?.data?.detail || 'Error removing user from subscription!');
        
      }
    }
);

const clearMessage = useMutation(
  async () => {
    return await axios.put(`/subscription/clear_flash_message?subscription_id=${getMyPlan?.data?.id}`);
  },
  {
    onSuccess: () => {
      getMyPlan.refetch();
    },
    onError: (error) => {
      toast.error("Error clearing message!");
    }
  }
);

    const addUsers = (user_id, e) => {
      if (e.target.checked) {
        if (getMyUsers.data.length >= getMyPlan.data?.max_no_of_practitioners) {
          toast.error("You have reached the maximum number of users allowed for your subscription!");
        } else {
          addUser.mutate(user_id);
        }
      } else {
        deleteUser.mutate(user_id);
      }
    }




    return (
        <div className={styles.myPlan}>
          {getMyPlan?.data?.flash_message && (
            <Alert
              severity="info"
              onClose={() => clearMessage.mutate()}
            >
              {getMyPlan.data.flash_message}
            </Alert>
          )}

            {getMyPlan.data?.status === 'Inactive' && (
                <div className={styles.inactiveBanner}>  
                  <p>You can reactivate your plan by clicking <a href="/plans">here</a> and selecting your desired plan</p>
                </div>
            )}

            <h3>My Plan</h3>
            {/* {getMyPlanLink?.data && <Button variant="contained" color="primary" className={styles.edit} onClick={() => window.open(getMyPlanLink?.data, '_blank')}>Manage Subscription</Button>} */}
           
            <div>
              <p>Active Plan: {getMyPlan.data?.subscription_name}</p>
              <p>Status: {getMyPlan.data?.status === 'inactive' ? 'Ended' : getMyPlan.data?.status}</p>
              <p>Renewal Type: {getMyPlan.data?.renewal_type?.charAt(0).toUpperCase() + getMyPlan.data?.renewal_type?.slice(1)}</p>

              {getMyPlan.data?.status === 'inactive' ? (
                <p>Ended At: {new Date(getMyPlan.data?.expire_date).toDateString()}</p>
              ) : (
                <p>Renewal Date: {new Date(getMyPlan.data?.renewal_date).toDateString()}</p>
              )}

<Box mt={2}>
  <Button 
    href={getMyPlanLink?.data} 
    target='_blank' 
    rel="noreferrer" 
    variant="contained" 
    color="warning"  
    style={{ borderRadius: '20px' }}
    disabled={!getMyPlanLink.data}
    startIcon={<PaidIcon />} 
  >
    Manage Billing
  </Button>
  &nbsp;
  <Button 
    href="/plans" 
    variant="contained" 
    color="info"  
    style={{ borderRadius: '20px' }}
    startIcon={<ScheduleIcon />} 
  >
    View Plans
  </Button> 
</Box>

            </div>

            
            { getMyPlan.data?.subscription_name !== 'Associate Dentist' && (
              <div className={styles.column}>
                <div>
                  <h4>Select up to {getMyPlan.data?.max_no_of_sites} Sites</h4>
                  <FormGroup>
                  <table className={styles.table}>
                    <tr>
                      <th>#</th>
                      <th>Site</th>
                      <th>Access</th>
                    </tr>
                    {getAllSites.data?.map((site: any, index: number) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{site.name}</td>
                        <td>
                          <FormControlLabel
                            key={site.id}
                            control={<Checkbox onChange={(checked) => addSites(site.id, checked)} checked={findIndex(getMySites.data, {site_id: site.id}) > -1} />}
                            label=""
                          />
                        </td> 
                      </tr>
                    ))}
                  </table>
                  </FormGroup>
                </div>
               
                {/* {isAdmin.data ? (
                      <div>
                          {getAllSites.data?.map((site: any, index: number) => (
                              <div key={site.id}>
                                  <h4>Select up to {getMyPlan.data?.max_no_of_practitioners} Practitioners</h4>
                                  <FormGroup>
                                      <table className={styles.table}>
                                          <tr>
                                              <th>#</th>
                                              <th>Practitioners</th>
                                              <th>Access</th>
                                          </tr>
                                          {getAllUsers.data?.map((user: any, index: number) => (
                                              <tr key={user.id}>
                                                  <td>{index + 1}</td>
                                                  <td>{`${user.first_name} ${user.last_name}`}</td>
                                                  <td>
                                                      <FormControlLabel
                                                          control={<Checkbox onChange={(checked) => addUsers(user.id, checked)} checked={findIndex(getMyUsers.data, { id: user.id }) > -1} />}
                                                          label=" "
                                                      />
                                                  </td>
                                              </tr>
                                          ))}
                                      </table>
                                  </FormGroup>
                              </div>
                          ))}
                      </div>
                  ) : null} */}
                 
              </div>
            )}
        </div>
    );
}
export default MyPlan;