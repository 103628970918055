// CameraModal.js
import React, { useEffect, useRef } from 'react';
import { Button, Modal, Box, Typography } from '@mui/material';

const CameraModal = ({ open, onClose }) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    const startCamera = async () => {
      if (videoRef.current) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { facingMode: { exact: 'environment' } }, // Use back camera
            audio: false,
          });
          videoRef.current.srcObject = stream;
        } catch (error) {
          console.error('Error accessing camera:', error);
        }
      }
    };

    if (open) {
      startCamera();
    }

    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        const tracks = videoRef.current.srcObject.getTracks();
        tracks.forEach((track) => track.stop()); // Stop all tracks when modal closes
      }
    };
  }, [open]);

  const snapshot = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    if (video) {
      const cw = video.clientWidth;
      const ch = video.clientHeight;

      // Draw the video frame to the canvas
      ctx.drawImage(video, 0, 0, cw, ch, 0, 0, cw / 2, ch / 3);
      // Set the image source to the canvas data
      imageRef.current.src = canvas.toDataURL();
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          bgcolor: 'white',
          borderRadius: '8px',
          padding: '20px',
          width: '90%', // Responsive width
          maxWidth: '500px', // Maximum width
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          boxShadow: 24,
        }}
      >
        <Typography variant="h6" component="h2">
          Capture Images
        </Typography>

        <video
          ref={videoRef}
          style={{
            width: '100%', // Responsive width for video
            borderRadius: '8px',
            marginTop: '20px', // Add margin for spacing
          }}
          autoPlay
          onClick={snapshot} // Take snapshot on click
        />

        <canvas
          ref={canvasRef}
          style={{ display: 'none' }} // Hide canvas
        />

        <img
          ref={imageRef}
          alt="Snapshot"
          style={{
            width: '100%', // Responsive width for image
            marginTop: '20px',
            borderRadius: '8px',
          }}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={onClose}
          sx={{ marginTop: '20px' }} // Add margin for spacing
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default CameraModal;
