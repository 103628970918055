import React, { useState } from "react";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import styles from "./Login.module.scss";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { Modal, Box } from "@mui/material";

function Login() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  const login = useMutation(
    () => {
      return axios.get(`/auth/get_dentally_request_auth_link`);
    },
    {
      onSuccess: (data) => {
        window.location.replace(data.data);
      },
    }
  );



  const openCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: { ideal: "environment" }, // Tries the back camera first
        },
      });
      setCameraStream(stream);
    } catch (error) {
      console.error("Error accessing camera: ", error);
      if (error.name === "NotFoundError") {
        try {
          const fallbackStream = await navigator.mediaDevices.getUserMedia({
            video: {
              facingMode: "user", // Front camera
            },
          });
          setCameraStream(fallbackStream);
        } catch (fallbackError) {
          console.error("Fallback camera access error: ", fallbackError);
          alert("No camera found or access to camera is denied.");
        }
      }
    }
  };


  return (
    <div className={styles.login}>
      <div className={styles.buttonContainer}>
        <Button
          className={styles.loginButton}
          onClick={() => login.mutate()}
          disabled={login.isLoading}
        >
          <p>Click here to login via</p>
          <img src="../dentally.jpg" alt="Dentally Logo" />
        </Button>

        {/* <Box ml={14} mb={6}>
        {isMobile ? ( <p className={styles.orText}>Or</p>) : null}
         
        </Box> */}

        {/* <Box ml={4}>
          {isMobile ? (
            <Button
              type="button"
              variant="contained"
              className={styles.createNew}
              onClick={openCamera}
            >
              Capture Images
              <AddPhotoAlternateIcon className={styles.icon} />
            </Button>
          ) : null}
        </Box> */}
      </div>
    </div>
  );
}
export default Login;
